import React, { useState, useEffect } from "react";
import Layout from "../../../components/chat-channel/Layout";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { VideoElement2 } from "../../../components/video";
import { useMedia } from "use-media";

import { OnScrollPopup } from "../hr-chatbot";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import {
  cta_1,
  cta_1_mob,
  cta_2,
  cta_2_mob,
  ExistingBlogCta,
} from "./4-reasons-to-use-ms-teams-as-a-digital-helpdesk";

import "react-accessible-accordion/dist/fancy-example.css";

const topImage = require("../../../assets/images/market_ui/goto_testimonial_cta.png");

const Section1 = require("../../../assets/img/blogs/automation_blog_1.png");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  const isSmall = useMedia({ maxWidth: "520px" });

  return (
    <>
      <TitleAndMetaTags
        title="Top 5 benefits of automating unlock account requests using chatbot"
        description="Learn how customers are benefiting from automating repetitive IT tasks such as unlock account using AI powered chatbot and task automation using workativ assistant. "
        keywords={[
          "Slack Chatbot, Automation, AI Chatbot",
          "workativ free trial",
          "Slack chatbot free trial",
          "Slack Chatbot",
        ]}
        ogImage={Section1}
        schema={ldSchema}
      />
      <section className="market_main_wrapper">
        <Container>
          <Layout backgroundColor={"_bg_market"} logoFor="ASSISTANT">
            <div className="grid_container_chatbot">
              <div className="container">
                <div className="row">
                  <div className="grid_container_chatbot_wrapper">
                    <div className="grid__wrapper">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 trial_header_left">
                        <div className="main-slider-left-market">
                          <h1 className="font-page-header">
                            5 Benefits of Having Automation for Unlock Account
                            Requests on IT Helpdesk
                          </h1>
                        </div>
                      </div>
                      <img
                        src={Section1}
                        alt="5 Benefits of Having Automation for Unlock Account
                            Requests on IT Helpdesk"
                      />
                      <div className="market_wrapper_page">
                        <p class="font-section-normal-text mt-4 line-height-2">
                          A report by{" "}
                          <a href="https://www.forrester.com/research/">
                            Forrester Research{" "}
                          </a>
                          concluded that about 20% of all support tickets are
                          related to passwords and unlocking an account. Another
                          study concluded that the cost of a help desk call
                          today averages around 30 Euro, which, undeniably is,
                          quite high in terms of customer serviceability cost.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Combining these two studies, it is not difficult to
                          estimate the expenditure IT service desks would bear
                          just to solve tickets related to account unlocking
                          issues. Thus, it has become essential for IT service
                          desks to{" "}
                          <a href="https://workativ.com/assistant/workflow-automation">
                            automate repetitive tasks{" "}
                          </a>
                          that take time to solve, but don’t add much value to
                          the business. This is when AI-powered unlock account
                          automation tools step in!
                        </p>
                      </div>

                      <NocodeWrapper />

                      {/* {isSmall ? null : <OnScrollPopup />} */}

                      <div>
                        <h2 className="font-section-sub-header-small-bold">
                          Why is There a Growing Need for{" "}
                          <a href="https://workativ.com/use-cases/unlock-account-automation">
                            Unlock Account Automation?
                          </a>
                        </h2>

                        <p class="font-section-normal-text line-height-2">
                          With growing security threats all over the world,
                          passwords are becoming more and more challenging. This
                          clearly suggests that the request for unlocking
                          accounts too will witness a rise. Now, this is a
                          problem for several reasons.
                        </p>
                        <ul
                          className="font-section-small-text"
                          style={{ width: "100%", float: "left" }}
                        >
                          <li className="pb-2">
                            It’s time-consuming for both users and IT service
                            desk agents.
                          </li>
                          <li className="pb-2">
                            It will prohibit the users from using an app or tool
                            if helpdesk agents are not available or accessible
                            at any point of time.
                          </li>
                          <li className="pb-2">
                            Costs add up quickly due to a large volume of
                            tickets.
                          </li>
                          <li className="pb-2">
                            Service desk agents may feel frustrated due to
                            spending so much time on elementary tasks.
                          </li>
                        </ul>

                        <p class="font-section-normal-text line-height-2">
                          There are a few use cases in which employees would
                          need to unlock account
                        </p>
                      </div>

                      <div>
                        <h6 className="font-section-sub-header-small-bold">
                          1. Preventing stale credentials
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          To keep accounts updated with new passwords, across
                          the entire organization using an automated bot. The
                          process follows a set time and does not consider the
                          urgency of a project or task that an employee is
                          working on plus, adding to the fact that it is an
                          uninitiated process by the end user. This is how it
                          goes:
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Usually, an unlock account tool is deployed to all the
                          devices of the network, to make the task of unlocking
                          an account hassle-free. The users are asked to
                          pre-register for the service by entering their user
                          identity and answering challenging questions related
                          to their account.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          The system then automatically redirects them to
                          ‘unlock account tab’, where they can reset their
                          password or unlock their account. A confirmation email
                          is sent by the system to notify the users regarding
                          the change of the password.
                        </p>

                        <h6 className="font-section-sub-header-small-bold">
                          2. An account is locked for some reason and the user
                          is unable to access a particular system.
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          To get his/her account unlocked, an employee can make
                          use of the organization’s system. Usually how this
                          works is that the request to unlock account is: a user
                          submits a request for unlocking an account on behalf
                          of another user from the service request available in
                          the Service Catalog. It is recorded as an incident and
                          moves through a chain of considerations and processes
                          to unlock the account for the user
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Why these systems are unnerving is that employees have
                          no way to get around the unlocking process faster.
                          Take an example for instance: employees may sometimes
                          be working remotely or may be locked out of an account
                          on an important day with a client or project.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          In such a case, having to go to the helpdesk for
                          support, or going through a system or requesting an
                          employee to register the request on their behalf is
                          not exactly cakewalk.
                        </p>

                        <h6 className="font-section-sub-header-small-bold">
                          Why Automating Unlock Account Requests through AI
                          based chat is a Great Idea.
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          To step up and deal with the real urgency of unlocking
                          accounts for users, automated request via a
                          self-service system is the best way to go. Enabling
                          end user service with innovative technology can make
                          the process hassle-free. With recent advances in AI
                          one such usability would be that an employee can send
                          in a request through an IT support bot. In the case of
                          an{" "}
                          <a href="https://workativ.com/conversational-ai-platform/it-helpdesk-chatbot">
                            AI-powered IT support chatbot
                          </a>{" "}
                          like Workativ Assistant, the bot understands the
                          request, gathers only a few details from the employee
                          to process the request and unlocks the account for the
                          individual in less than 2 minutes! Instant unlock in
                          the middle of an important meeting, or, being
                          available 24/7 is the cherry on the cake for
                          end-users.
                        </p>
                        <h6 className="font-section-sub-header-small-bold">
                          Benefits of Automating Unlock Account Requests
                        </h6>
                        <ul
                          className="font-section-small-text"
                          style={{ width: "100%", float: "left" }}
                        >
                          <li className="line-height-2 pb-3">
                            <span className="font-section-normal-text-medium">
                              {" "}
                              Improves Speed and Efficiency –{" "}
                            </span>{" "}
                            There is no need for the users to wait for an agent
                            to unlock or reset the account. Rather, the users
                            are empowered to
                            <a href="https://workativ.com/conversational-ai-platform/automated-resolution">
                              &nbsp;perform the task themselves,
                            </a>{" "}
                            with immediate and confirmed results.
                          </li>
                          <li className="line-height-2 pb-3">
                            <span className="font-section-normal-text-medium">
                              {" "}
                              Introduces the User to Automation –{" "}
                            </span>{" "}
                            Chatbot for IT helpdesk as a tool to unlock accounts
                            or{" "}
                            <a href="https://workativ.com/use-cases/reset-password-automation">
                              reset password
                            </a>{" "}
                            is one of the basic steps taken by IT helpdesks, to
                            introduce the customers to automation. Unlike
                            traditional password resets or unlocking accounts,
                            these tools eliminate the need for phone calls and
                            reduce the hold time, consequently introducing the
                            customers to automation.
                          </li>
                          <li className="line-height-2 pb-3">
                            <span className="font-section-normal-text-medium">
                              {" "}
                              Reduces Cost –{" "}
                            </span>{" "}
                            As already discussed, around 20% of the service desk
                            calls are related to unlocking accounts, which is
                            money that could be easily channelized to expand the
                            overall IT service desk operations.
                            <br /> So,{" "}
                            <a href="https://workativ.com/conversational-ai-platform/cost-reduction">
                              rather than hiring serve desk agents
                            </a>{" "}
                            and manually unlocking the account unlock requests,
                            leveraging the power of AI-based tools to streamline
                            the operations is always a better idea
                          </li>
                          <li className="line-height-2 pb-3">
                            <span className="font-section-normal-text-medium">
                              {" "}
                              Greater Visibility and Analytics –{" "}
                            </span>
                            Intelligent IT Support bot provides an in-depth
                            analysis of user adoption. This enables service desk
                            agents to analyse who are still not using the tool
                            and are calling the agents. This again helps them
                            decipher the ratio between the number of users who
                            have adopted to automation to the users who still
                            haven’t.
                          </li>
                          <li className="line-height-2 pb-3">
                            <span className="font-section-normal-text-medium">
                              {" "}
                              Easily Integrated Solution –{" "}
                            </span>
                            <a href="https://workativ.com/conversational-ai-platform/conversational-ai-chatbot">
                              &nbsp; Chatbot for IT helpdesk
                            </a>
                            being implemented as a software-as-a-service
                            solution on the system, reduces the load on the IT
                            ServiceDesk agents. Also, the tools being powered by
                            Cloud keeps user information secure and readily
                            available.
                          </li>
                        </ul>
                      </div>

                      <div className="market_wrapper_page">
                        <h5 className="font-section-sub-header-small-bold">
                          How is Workativ Assistant Changing the Scenario?
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Workativ Assistant is a revolutionary AI-powered
                          unlock account automation tool that is redefining
                          unlock account automation. No coding is required, and
                          one can seamlessly integrate the system in just a few
                          clicks. Also, one can choose from a range of
                          applications to create a fully automated virtual
                          assistant. The best part? It can be easily deployed to
                          the chat channel to solve unlock account requests in
                          just a few minutes. Pick your slot today for a{" "}
                          <a href="https://workativ.com/conversational-ai-platform/demo">
                            free demo of Workativ Assistant{" "}
                          </a>
                          and we’ll also be happy to answer any questions you
                          might have.
                        </p>
                      </div>
                      <ExistingBlogCta
                        ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
                        ButtonText="Book a Demo"
                        isColor="black"
                        backgroundImage={cta_1}
                        mobileBackgroundImage={cta_1_mob}
                      />
                      <div className="most_popular_links">
                        <div className="most_popular_header font-section-sub-header-bold">
                          Related Articles
                        </div>
                        <div className="most_popular_ul">
                          <ul
                            className="section__ul"
                            style={{ width: "100%", float: "left" }}
                          >
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/how-ai-powered-chatbots-with-automated-workflows-can-help-digital-workers">
                                Digital Workers to the rescue: Reasons why
                                Digital Transformations fail and how AI-Powered
                                Chatbots with Automated Workflows can help
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/top-8-human-resource-tasks-to-automate-for-better-employee-experience">
                                {" "}
                                Top 8 Human Resource Tasks to Automate for
                                better Employee Experience
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/conversational-ai-chatbot-for-employee-service">
                                Conversational AI Chatbot for employee service
                                automation
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="button_last">
                        <button>
                          <a href="https://blog.workativ.com/category/conversational-ai">
                            Conversational AI
                          </a>
                        </button>
                      </div>
                    </div>
                    <div className="sticky_right_img">
                      <a href="/conversational-ai-platform/case-studies/goto-implements-chatbot-for-it-helpdesk-automation">
                        {" "}
                        <img src={topImage} alt="goto testimonial cta" />{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <GoToPopup />
            <CookiesPoup /> */}
            <RequestForm isFooterForm={true} />
          </Layout>
        </Container>
      </section>
    </>
  );
}

const NocodeWrapper = () => {
  return (
    <div className="nocode_wrapper mt-0 ">
      <h4>No Code - Free Conversational AI Chatbot </h4>
      <button>
        <a
          href="https://assistant.workativ.com/authentication/u/direct-signup"
          alt="Conversational AI chatbot"
        >
          Get Started
        </a>
      </button>
    </div>
  );
};
